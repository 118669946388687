




import Vue from 'vue'
import EvaluateTender from '@/views/Tender/Evaluation/views/EvaluateTender.vue'

export default Vue.extend({
  name: 'EvaluateBuyingTender',
  components: { EvaluateTender },
})
